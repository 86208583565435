* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
}

html,
body {
    height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;    
}

@media all and ( min-width: 576px ) {
    body {
        background-image: url('../assets/bg-signup.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: cover;
        background-attachment: fixed;
    }    
}    

button, input, body, textarea, select {
    font-family: 'Inter', sans-serif;
}

button {
    cursor: pointer;
}